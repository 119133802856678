<template>
  <form-dialog
    :title="title"
    :formItems="formItems"
    :formData="formData"
    :confirmLoading="confirmLoading"
    @close="$emit('close')"
    @confirm="confirm"
  >
    <template v-slot:templateIds>
      <multiple-select :selectOptions="formItems[1].options" v-model="formData.templateIds"></multiple-select>
    </template>
    <template v-slot:url>
      <upload-apk
        :uploadApiFunc="screenVersionUpload"
        v-model="formData.url"
        :fileName="formData.fileName"
        @upload-success="uploadSuccess"
      ></upload-apk>
    </template>
  </form-dialog>
</template>

<script>
import { ApiVersion } from "@/api/version";

export default {
  name: "VersionEditDialog",
  props: {
    projectId: {
      type: [String, Number],
      default: null
    },
    detail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      screenVersionUpload: ApiVersion.screenVersionUpload,
      formItems: [
        {
          label: "大屏版本号",
          prop: "screenVersion",
          type: "input",
          rules: [{ required: true, message: "请输入", trigger: "blur" }]
        },
        {
          label: "适用户型",
          prop: "templateIds",
          options: [],
          slot: true,
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        {
          label: "大屏软件包",
          prop: "url",
          slot: true,
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        }
      ],
      formData: {},
      confirmLoading: false
    };
  },
  computed: {
    title() {
      return `${this.detail ? "编辑" : "新增"}版本`;
    }
  },
  async mounted() {
    await this.initTemplateOptions();
    this.initData();
  },
  methods: {
    initData() {
      if (this.detail) {
        this.formData = { ...this.detail };
        const _list = this.formItems[1].options.map((item) => {
          if (this.formData.templateIds.includes(item.value)) {
            item.disabled = false;
          }
          return item;
        });
        this.$set(this.formItems[1], "options", _list);
      }
    },
    async initTemplateOptions() {
      const res = await ApiVersion.templateOptions(this.projectId);
      this.$set(this.formItems[1], "options", res);
    },
    uploadSuccess({ name }) {
      this.formData.fileName = name;
    },
    async confirm() {
      const params = {
        projectId: this.projectId,
        ...this.formData
      };
      params.templateIds = params.templateIds.filter((item) => item !== "全选");
      this.confirmLoading = true;
      try {
        if (params.id) {
          await ApiVersion.edit(params);
        } else {
          await ApiVersion.add(params);
        }
        this.$message.success(params.id ? "修改成功" : "添加成功");
        this.$emit("success");
      } catch (error) {
        console.log(error);
      }
      this.confirmLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
