<template>
  <div class="space-list">
    <building-tree :level="2" @node-click="selectBuilding" />
    <div class="table-page space-list-left">
      <ld-table
        ref="table"
        rightMenuWidth="150"
        :columns="columns"
        :tableData="tableData"
        :loading="tableLoading"
        @update-data="initTableData"
      >
        <template v-slot:handleBarLeft>
          <el-button type="primary" size="small" @click="onAdd">新增</el-button>
          <upload-button
            class="btn"
            label="大屏配置文件上传"
            :customUpload="onUploadScreenConfig"
            :loading="uploadLoading"
          />
        </template>
        <template v-slot:rightMenu="{ scope }">
          <table-button type="primary" @click="onEdit(scope.row)">修改</table-button>
          <table-button @click="onDelete(scope.row)" type="danger">删除</table-button>
        </template>
      </ld-table>
    </div>
    <VersionEditDialog
      v-if="versionEditDialogVisible"
      :projectId="projectId"
      :detail="currentDetail"
      @close="versionEditDialogVisible = false"
      @success="versionEditDialogSuccess"
    ></VersionEditDialog>
  </div>
</template>

<script>
import { ApiVersion } from "@/api/version";
import VersionEditDialog from "./components/VersionEditDialog";

export default {
  name: "VersionList",
  components: {
    VersionEditDialog
  },
  data() {
    return {
      columns: [
        {
          label: "大屏版本号",
          prop: "screenVersion"
        },
        {
          label: "适用户型",
          prop: "templateDesc"
        },
        {
          label: "最后修改时间",
          prop: "updateTime"
        },
        {
          label: "修改人",
          prop: "updateUser"
        },
        {
          label: "大屏软件包",
          prop: "fileName"
        }
      ],
      tableData: [],
      tableLoading: false,
      realestateId: null,
      projectId: null,
      versionEditDialogVisible: false,
      currentDetail: null,
      uploadLoading: false
    };
  },
  methods: {
    initTableData() {
      let params = {
        realestateId: this.realestateId,
        projectId: this.projectId
      };
      this.tableLoading = true;
      ApiVersion.list(params)
        .then((res) => {
          this.tableData = res;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 选择楼盘
    selectBuilding(value) {
      this.realestateId = value[0];
      this.projectId = value[1];
      this.initTableData();
    },
    async onUploadScreenConfig(files) {
      const form = new FormData();
      files.forEach((item) => {
        form.append("file", item);
      });
      this.uploadLoading = true;
      await ApiVersion.uploadScreenConfig(form).finally(() => {
        this.uploadLoading = false;
      });
      this.$message.success("上传成功");
    },
    onAdd() {
      this.currentDetail = null;
      this.versionEditDialogVisible = true;
    },
    onEdit(row) {
      this.currentDetail = row;
      this.versionEditDialogVisible = true;
    },
    versionEditDialogSuccess() {
      this.versionEditDialogVisible = false;
      this.initTableData();
    },
    onDelete({ id }) {
      this.$delItem(() => {
        ApiVersion.delete(id).then(() => {
          this.$message.success("删除成功");
          this.initTableData();
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.space-list {
  display: flex;
  height: 100%;
  &-left {
    flex: 1;
  }
}
</style>
